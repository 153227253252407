import React, { ReactElement, useState } from 'react';
import { ApolloClient, InMemoryCache, HttpLink, ApolloProvider } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

export default function AppProvider(props: { children: ReactElement }) {
  const { children } = props;

  let client = new ApolloClient({
    cache: new InMemoryCache(),
  });

  if (typeof fetch !== 'undefined') {
    client = new ApolloClient({
      cache: new InMemoryCache(),
      //@ts-ignore
      link: createUploadLink({
        uri: process.env.GATSBY_GRAPHQL_HOST,
      }),
    });
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
