import { MenuItemType } from '@paljs/ui/types';

const items: MenuItemType[] = [
  {
    title: 'Home Page',
    icon: { name: 'home' },
    link: { to: '/dashboard' },
  },
  {
    title: 'FEATURES',
    group: true,
  },
  {
    title: 'Articles',
    icon: { name: 'book-open' },
    children: [
      {
        title: 'All Articles',
        link: { to: '/articles/all-articles' },
      },
    ],
  },
  {
    title: 'Memes',
    icon: { name: 'book-open' },
    link: { to: '/memes' },
  },
  {
    title: 'Places',
    icon: { name: 'book-open' },
    link: { to: '/places' },
  },
];

export default items;
